.generic-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  border-radius: 8px;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  transition: transform 0.2s ease-in-out;
}

@for $i from 1 through 12 {
  .width-#{$i} {
    width: calc(100% / (12 / $i));
  }
}
